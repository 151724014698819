import { Moment } from '@tools/utils';
import { SurveyType, UserActionTypes } from './enums';
import { UserAction, UserState } from './types';

const initialDate = Moment().toISOString();

export const initialState: UserState = {
    email: '',
    site: {
        id: '',
        timezone: '',
    },
    name: {
        first: '',
        last: '',
    },
    source: '',
    tenantId: '',
    loginCount: 1,
    customerId: '',
    hasSales: false,
    accountDeleted: false,
    joinDate: initialDate,
    registrationDate: initialDate,
    shouldGetGvsInfo: false,
    gvsUserInfo: null,
    surveys: {
        [SurveyType.HOME_PROFILE]: {
            data: undefined,
            error: undefined,
            pristine: true,
        },
        [SurveyType.CMS]: {
            completed: [],
        },
    },
    encryptedReference: '',
    privacyData: {
        error: false,
        loading: false,
        isAlreadySent: false,
        requestDelete: false,
        isDownloadReady: false,
        requestDate: null,
        expirationDate: null,
    },
    mobileAppUsageCount: 0,
    billingRegions: {
        data: null,
        error: false,
        loading: true,
    },
    billingRegion: {
        data: null,
        error: false,
        loading: true,
    },
    isGridX: false,
    consents: [],
};

export default function reducer(
    state: UserState = initialState,
    action: UserAction,
): UserState {
    switch (action.type) {
        case UserActionTypes.SET_EMAIL:
        case UserActionTypes.SET_USER_DATA:
        case UserActionTypes.SET_GVS_INFO:
        case UserActionTypes.SET_REGISTRATION_DATE:
            return {
                ...state,
                ...action.payload,
            };

        case UserActionTypes.GET_SITES:
            return {
                ...state,
                site: action.payload,
            };

        case UserActionTypes.SET_HOME_PROFILE_SURVEY: {
            const surveyData = {
                ...state.surveys[SurveyType.HOME_PROFILE].data,
                ...action.payload.data,
            };

            return {
                ...state,
                surveys: {
                    ...state.surveys,
                    [SurveyType.HOME_PROFILE]: {
                        error: action.payload.error,
                        data: surveyData,
                        pristine: Object.entries(surveyData).length === 0,
                    },
                },
            };
        }

        case UserActionTypes.SET_ENCRYPTED_REFERENCE:
            return {
                ...state,
                encryptedReference: action.payload,
            };

        case UserActionTypes.ACCOUNT_DELETE_SUCCESS:
            return {
                ...initialState,
                accountDeleted: true,
            };

        case UserActionTypes.SET_COMPLETED_CMS_SURVEYS:
            return {
                ...state,
                surveys: {
                    ...state.surveys,
                    cms: {
                        ...state.surveys.cms,
                        completed: action.payload,
                    },
                },
            };

        case UserActionTypes.PRIVACY_DATA_ERROR:
        case UserActionTypes.PRIVACY_DATA_LOADING:
            return {
                ...state,
                privacyData: {
                    ...state.privacyData,
                    ...action.payload,
                },
            };

        case UserActionTypes.DELETE_ACCOUNT:
        case UserActionTypes.GET_PRIVACY_DATA:
        case UserActionTypes.PRIVACY_DATA_REQUEST:
            return {
                ...state,
                privacyData: {
                    ...state.privacyData,
                    ...action.payload,
                    error: false,
                    loading: false,
                },
            };

        case UserActionTypes.SET_MOBILE_APP_USAGE_COUNT:
            return {
                ...state,
                mobileAppUsageCount: action.payload,
            };

        case UserActionTypes.SET_BILLING_REGIONS_DATA:
            return {
                ...state,
                billingRegions: {
                    ...state.billingRegions,
                    ...action.payload,
                },
            };

        case UserActionTypes.SET_BILLING_REGION_DATA:
            return {
                ...state,
                billingRegion: {
                    ...state.billingRegions,
                    ...action.payload,
                },
            };

        case UserActionTypes.SET_GVS_ACKNOWLEDGED:
            return {
                ...state,
                gvsUserInfo: null,
            };

        case UserActionTypes.SET_GRIDX_STATUS:
            return {
                ...state,
                isGridX: action.payload.isGridX,
            };
        case UserActionTypes.SET_CONSENT_DATA:
            return {
                ...state,
                consents: action.payload,
            };

        default:
            return state;
    }
}
