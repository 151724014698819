import { memo, FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ShieldIcon from '@assets/icons/misc/shield-icon.svg';

import { Prompt } from '@components';
import { Icon } from '@eon-home/react-library';

import { useGetDataAnalysisConsent } from '@store/selectors';
import { useDataAnalysisConsent } from '@tools/utils';

import { Routes } from '@tools/enums/routes';

import './index.scss';

export const DataConsent: FC = () => {
    const { t } = useTranslation();
    const dataAnalysisConsent = useGetDataAnalysisConsent();
    const { handleStatus, isVisible } =
        useDataAnalysisConsent(dataAnalysisConsent);

    const [shouldShow, setShouldShow] = useState<boolean>(
        !(dataAnalysisConsent?.opting ?? false),
    );

    const { pathname } = useLocation();

    useEffect(() => {
        setShouldShow(isVisible);
    }, [isVisible]);

    if (
        !shouldShow ||
        dataAnalysisConsent?.opting ||
        pathname.includes(Routes.MORE_DATA_ANALYSIS)
    ) {
        return null;
    }

    return (
        <Prompt
            title={t<string>('Consent for data analysis')}
            onClose={() => setShouldShow(false)}
            onConfirm={() => handleStatus(true)}
            onCancel={() => handleStatus(false)}
            confirmText={t<string>('Accept')}
            cancelText={t<string>('Decline')}
            small={true}
            alignLeft={true}
        >
            <figure className="c-data-consent__icon">
                <Icon src={ShieldIcon} />
            </figure>
            <p>{t('Consent for data analysis modal description')}</p>
        </Prompt>
    );
};

export default memo(DataConsent);
