import moment from 'moment';
import { DurationInputArg1, DurationInputArg2 } from 'moment-timezone';

export const consentActionIsOlderThan = (
    consentActionDate: number | string | Date,
    subtractCount: DurationInputArg1 = 6,
    subtractUnit: DurationInputArg2 = 'months',
) => {
    const inputDate = moment(consentActionDate);
    const today = moment();

    const subtractedDate = today.subtract(subtractCount, subtractUnit);

    return inputDate.isBefore(subtractedDate);
};
