import { useMemo } from 'react';

import {
    ScheduleChargingModel,
    GenericSmartChargingModelModeEnum,
    AutopilotChargingModel,
} from '@swagger-http';

import { ChargingModeData } from '@store/types';
import { MarketingTileModel } from '@tools/types';
import { MarketingTileTypes, Scope } from '@tools/enums';
import { checkForScopes, useHasAutopilotChargingReadScope } from '@tools/utils';
import {
    AUTOPILOT_DEFAULT_TARGET_SOC,
    AUTOPILOT_DEFAULT_DEPATURE_TIME,
} from '@store/actions';
import {
    useAppSelector,
    useHasEmobilityTokenExpired,
    useEmobilityReadingStartDate,
} from '@store/selectors';

const {
    Schedule,
    DynamicPriceCharging,
    GridFriendlyCharging,
    SolarAssistedCharging,
    HomePlugAndCharge,
    Autopilot,
    SolarWeatherCharging,
} = GenericSmartChargingModelModeEnum;

export const useHasElectricCar = () =>
    !!useAppSelector(
        (state) => state.emobility.electricCarData?.electricCar?.configId,
    );

// TODO EONFEH-13779: check this implementation
export const useIsElectricCarCharging = () =>
    useAppSelector(
        (state) =>
            state.liveData.telemetries.emobility.electricCar
                .isElectricCarCharging,
    );

export const useIsElectricCarOnline = () =>
    useAppSelector(
        (state) =>
            state.liveData.telemetries.emobility.electricCar
                .isElectricCarOnline,
    );

// prettier-ignore
export const useElectricCarConfigId = () => useAppSelector((state) => state.emobility.electricCarData?.electricCar?.configId) || '';

export const useElectricCarConnectButton = (
    marketingTiles: MarketingTileModel[],
): boolean => {
    const tile = marketingTiles.find(
        (item) => item.type === MarketingTileTypes.ELECTRIC_CAR,
    );

    return (
        !!tile &&
        tile.shouldRender &&
        checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_WRITE])
    );
};

// TODO EONFEH-13869: this is always true because we get '' from the backend in any case -> rethink this
export const useHasElectricCarTokenExpired = () => {
    const tokenExpiryDate = useAppSelector(
        (state) =>
            state.emobility.electricCarData?.electricCar?.deviceGateway
                ?.clientAccess?.tokenExpiryDate,
    );

    return useHasEmobilityTokenExpired(tokenExpiryDate);
};

export const useElectricCarBrand = () =>
    useAppSelector(
        (state) =>
            state.emobility.electricCarData?.electricCar?.deviceConfiguration
                ?.information?.brand ?? '',
    );

export const useElectricCarModel = () =>
    useAppSelector(
        (state) =>
            state.emobility.electricCarData?.electricCar?.deviceConfiguration
                ?.information?.model ?? '',
    );

export const useIsAddressSet = () =>
    useAppSelector(
        (state) =>
            state.emobility.electricCarData?.electricCar?.deviceConfiguration
                ?.isAddressSet,
    );

export const useElectricCarReadingStartDate = () => {
    const readingStartDate = useAppSelector(
        (state) =>
            state.emobility.electricCarData.electricCar?.deviceConfiguration
                ?.readingStartDate,
    );

    return useEmobilityReadingStartDate(readingStartDate);
};

export const useElectricCarChargingModes = () => {
    const data = useAppSelector(
        (state) => state.emobility.electricCarData.chargingModes.data,
    );
    const permissions = useElectricCarChargingModesPermissions();

    return data.filter(
        (item) => item.mode !== GridFriendlyCharging && permissions[item.mode],
    );
};

export const useElectricCarChargingModesErrorAndLoading = () => {
    const { error, loading } = useAppSelector(
        (state) => state.emobility.electricCarData.chargingModes,
    );
    return { error, loading };
};

export const useElectricCarSmartSchedulingData = () => {
    const data = useAppSelector((state) =>
        state.emobility.electricCarData.chargingModes.data.find(
            (item) => item.mode === Schedule,
        ),
    ) as ChargingModeData<ScheduleChargingModel> | undefined;

    return useMemo(
        () => ({
            active: !!data?.parameters.active,
            hasData: !!data?.parameters,
            defaultEnd: data?.parameters.end || '1200',
            defaultStart: data?.parameters.start || '0000',
        }),
        [data?.parameters],
    );
};

export const useElectricCarAutopilotData = () => {
    const data = useAppSelector((state) =>
        state.emobility.electricCarData.chargingModes.data.find(
            (item) => item.mode === Autopilot,
        ),
    ) as ChargingModeData<AutopilotChargingModel> | undefined;

    return useMemo(
        (): AutopilotChargingModel => ({
            active: !!data?.parameters.active,
            departureTime:
                data?.parameters.departureTime ||
                AUTOPILOT_DEFAULT_DEPATURE_TIME,
            minimumSoc: data?.parameters.minimumSoc,
            targetSoc:
                data?.parameters.targetSoc ?? AUTOPILOT_DEFAULT_TARGET_SOC,
        }),
        [data?.parameters],
    );
};

export const useElectricCarLastChargingSessionData = () =>
    useAppSelector(
        (state) => state.insights?.emobility?.electricCar.lastChargingSession,
    );

export const useElectricCarChargingModesPermissions = (): {
    [V in GenericSmartChargingModelModeEnum]?: boolean;
} => {
    // Autopilot may only occur all alone, so we need to check this in the other modes
    const hasAutopilotPermission = useHasAutopilotChargingReadScope();

    // checking whether autopilot permission is given for the modes which do not have their own scope
    // as they are not allowed to be active together with autopilot
    return {
        [Schedule]:
            checkForScopes([Scope.EMOBILITY_SMART_CHARGING_READ]) &&
            !hasAutopilotPermission,
        [SolarAssistedCharging]: checkForScopes([
            Scope.EMOBILITY_SOLAR_CHARGING_READ,
        ]),
        [GridFriendlyCharging]:
            checkForScopes([Scope.EMOBILITY_SMART_CHARGING_READ]) &&
            !hasAutopilotPermission,
        [DynamicPriceCharging]: checkForScopes([
            Scope.EMOBILITY_DYNAMIC_PRICE_CHARGING_READ,
        ]),
        [HomePlugAndCharge]:
            checkForScopes([Scope.EMOBILITY_SMART_CHARGING_READ]) &&
            !hasAutopilotPermission,
        [Autopilot]: checkForScopes([Scope.EMOBILITY_AUTOPILOT_READ]),
        [SolarWeatherCharging]: checkForScopes([Scope.PVFORECAST_WRITE]),
    };
};

export const useElectricCarChargingModesCount = () => {
    const permissions = useElectricCarChargingModesPermissions();
    const hasAutopilotPermission = useHasAutopilotChargingReadScope();

    // no need to compute the charging modes count if Autopilot is included as this is always the only one
    if (hasAutopilotPermission) return 1;

    return Object.keys(permissions).reduce(
        (acc: boolean[], curr: GenericSmartChargingModelModeEnum) => {
            if (
                permissions[curr] &&
                ![
                    GenericSmartChargingModelModeEnum.GridFriendlyCharging,
                    GenericSmartChargingModelModeEnum.HomePlugAndCharge,
                ].includes(curr)
            ) {
                acc.push(true);
            }

            return acc;
        },
        [],
    ).length;
};
